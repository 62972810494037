import React from 'react';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import {
  GLOBAL_BOTTOM_HEADER_HEIGHT,
  GLOBAL_TOP_HEADER_HEIGHT,
} from 'constants/height';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Colors } from 'styles/colors';

import type { BoxProps } from '@mui/material/Box';
import type { HeaderItem } from 'utils/getHeaderItems';

import conderlandImage from 'public/images/coderland.png';

const LOGO_IMAGE_WIDTH = 194; // px
const LOGO_IMAGE_HEIGHT = 38; // px

interface GlobalHeaderProps {
  headerItems: HeaderItem[];
  top?: BoxProps['top'];
}

const StyledLogoAnchor = styled.a`
  display: block;
  height: ${LOGO_IMAGE_HEIGHT}px;
`;

const GlobalHeader: React.FC<GlobalHeaderProps> = ({ headerItems, top }) => {
  const router = useRouter();

  const [value, setValue] = React.useState(0);

  // prefetching
  React.useEffect(() => {
    // https://nextjs.org/docs/api-reference/next/router#routerprefetch
    headerItems.forEach(item => {
      if (item?.link.startsWith('/')) {
        void router.prefetch(item.link);
      }
    });
  }, [router, headerItems]);

  // initialize tab state
  React.useEffect(() => {
    const itemIndex = headerItems.findIndex(
      item => item.link === router.asPath
    );

    if (itemIndex !== -1) {
      setValue(itemIndex);
    }
  }, [headerItems, router]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleClick = (item: HeaderItem) => {
    if (item.isNewWindow) {
      window.open(item.link);
    } else {
      void router.push(item.link);
    }
  };

  return (
    <Box
      component="header"
      position="fixed"
      top={top}
      left={0}
      width="100%"
      zIndex={10}
      bgcolor={Colors.white}
      borderBottom={1}
      borderColor={Colors.grey3}
      sx={{
        transition: 'all .3s ease',
      }}
    >
      {/* top header */}
      <Box
        height={GLOBAL_TOP_HEADER_HEIGHT}
        alignItems="center"
        px={3}
        display="flex"
      >
        <Box display="flex" gap={3}>
          {/* logo */}
          <Box display="flex" alignItems="center">
            <Link href="/" passHref legacyBehavior>
              <StyledLogoAnchor>
                <Image
                  src={conderlandImage}
                  alt="coderland logo"
                  width={LOGO_IMAGE_WIDTH}
                  height={LOGO_IMAGE_HEIGHT}
                />
              </StyledLogoAnchor>
            </Link>
          </Box>

          {/* desktop header item */}
          <Box gap={1} display={{ xs: 'none', sm: 'none', md: 'flex' }}>
            {headerItems.map(item => {
              if (item.isNewWindow) {
                return (
                  <Button
                    key={item.title}
                    size="large"
                    href={item.link}
                    target="_blank"
                    color="inherit"
                    sx={{
                      minWidth: 0,
                    }}
                  >
                    {item.title}
                  </Button>
                );
              }

              return (
                <Link key={item.title} href={item.link} passHref legacyBehavior>
                  <a>
                    <Button
                      size="large"
                      color="inherit"
                      sx={{
                        minWidth: 0,
                      }}
                    >
                      {item.title}
                    </Button>
                  </a>
                </Link>
              );
            })}
          </Box>
        </Box>
      </Box>

      {/* bottom header for tablet or mobile */}
      <Box
        height={GLOBAL_BOTTOM_HEADER_HEIGHT}
        sx={{ width: '100%' }}
        display={{ xs: 'block', sm: 'block', md: 'none', overflowX: 'auto' }}
      >
        <Tabs value={value} onChange={handleChange} variant="scrollable">
          {headerItems.map((item, index) => (
            <Tab
              key={item.title}
              label={
                <Typography variant="body1" fontWeight={700}>
                  {item.title}
                </Typography>
              }
              sx={{ minWidth: 0, ml: index === 0 ? 1 : 0 }}
              onClick={() => handleClick(item)}
            />
          ))}
        </Tabs>
      </Box>
    </Box>
  );
};

export default GlobalHeader;
