import React from 'react';
import { Box } from '@mui/material';
import {
  GLOBAL_BOTTOM_HEADER_HEIGHT,
  GLOBAL_TOP_HEADER_HEIGHT,
  TOP_BANNER_HEIGHT,
} from 'constants/height';

import GlobalFooter from './GlobalFooter';
import GlobalHeader from './GlobalHeader';

import type { BoxProps } from '@mui/material';
import type { HeaderItem } from 'utils/getHeaderItems';

interface LayoutProps {
  children: React.ReactNode;
  headerItems: HeaderItem[];
  paddingBottom?: BoxProps['paddingBottom'];
  hasTopBanner?: boolean;
  footer?: {
    description: string;
  };
}

const Layout: React.FC<LayoutProps> = ({
  children,
  headerItems,
  paddingBottom,
  hasTopBanner,
  footer,
}) => {
  const [isMoveUpwards, setIsMoveUpwards] = React.useState(false);

  // move when scroll down
  React.useEffect(() => {
    const scrollListener = () => {
      setIsMoveUpwards(window.scrollY > 10);
    };

    if (hasTopBanner) {
      window.addEventListener('scroll', scrollListener);
    }

    return () => {
      if (hasTopBanner) {
        window.removeEventListener('scroll', scrollListener);
      }
    };
  }, [hasTopBanner]);

  const getOffsetHeight = () => {
    if (!hasTopBanner) {
      return 0;
    }

    if (isMoveUpwards) {
      return 0;
    }

    return TOP_BANNER_HEIGHT;
  };

  return (
    <>
      {/* Header */}
      <GlobalHeader headerItems={headerItems} top={getOffsetHeight()} />

      {/* Main */}
      <Box
        component="main"
        paddingTop={{
          xs: `${
            getOffsetHeight() +
            GLOBAL_TOP_HEADER_HEIGHT +
            GLOBAL_BOTTOM_HEADER_HEIGHT
          }px`,
          sm: `${
            getOffsetHeight() +
            GLOBAL_TOP_HEADER_HEIGHT +
            GLOBAL_BOTTOM_HEADER_HEIGHT
          }px`,
          md: `${getOffsetHeight() + GLOBAL_TOP_HEADER_HEIGHT}px`,
        }}
        sx={{
          transition: 'all .3s ease',
        }}
      >
        {children}
      </Box>

      {/* Footer */}
      <GlobalFooter paddingBottom={paddingBottom} footer={footer} />
    </>
  );
};

export default Layout;
