import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Portal, styled } from '@mui/material';
import { TOP_BANNER_HEIGHT } from 'constants/height';
import { getCookie } from 'lib/utils/getCookie';
import { setCookie } from 'lib/utils/setCookie';
import Image from 'next/image';

interface TopBannerProps {
  id: string;
  image?: {
    url?: string | null;
    title?: string | null;
  };
  imageTablet?: {
    url?: string | null;
    title?: string | null;
  };
  imageMobile?: {
    url?: string | null;
    title?: string | null;
  };
  link: string;
  closeButtonColor?: string;
}

const StyledBox = styled(Box)(({ theme }) => ({
  '.mobile, .tablet, .desktop': {
    display: 'none',
  },
  top: 0,
  left: 0,
  width: '100vw',
  position: 'fixed',
  zIndex: 10,
  [theme.breakpoints.down('sm')]: {
    '.mobile': {
      display: 'block',
    },
  },
  [theme.breakpoints.between('sm', 'md')]: {
    '.tablet': {
      display: 'block',
    },
  },
  [theme.breakpoints.up('md')]: {
    '.desktop': {
      display: 'block',
    },
  },
}));

const ImageContainer: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <Box
      height={TOP_BANNER_HEIGHT}
      position="relative"
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      // desktop image width
      maxWidth={1920}
      margin="0 auto"
    >
      {children}
    </Box>
  );
};

const Anchor: React.FC<{ href: string }> = ({ href }) => {
  return (
    <Box
      component="a"
      display="block"
      width="100%"
      height="100%"
      href={href}
      target="_blank"
      rel="noreferrer"
      sx={{
        cursor: 'pointer',
        zIndex: 1,
      }}
    />
  );
};

const TopBanner: React.FC<TopBannerProps> = ({
  id,
  imageMobile,
  imageTablet,
  image,
  link,
  closeButtonColor = 'inherit',
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isMoveUpwards, setIsMoveUpwards] = React.useState(false);

  React.useEffect(() => {
    if (!getCookie(`top-banner-${id}`)) {
      setIsOpen(true);
    }
  }, [id]);

  // hide when scroll down
  React.useEffect(() => {
    const scrollListener = () => {
      if (window.scrollY > 10) {
        setIsMoveUpwards(true);
      } else {
        setIsMoveUpwards(false);
      }
    };

    window.addEventListener('scroll', scrollListener);

    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    setCookie(`top-banner-${id}`, 'hide');
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Portal>
      <StyledBox
        sx={{
          transition: 'all .3s ease',
          top: isMoveUpwards ? -TOP_BANNER_HEIGHT : 0,
        }}
      >
        <ImageContainer>
          {/* mobile */}
          <Box
            className="mobile"
            width="100%"
            height="100%"
            position="absolute"
          >
            {imageMobile ? (
              <Image
                src={imageMobile?.url ?? ''}
                alt={imageMobile?.title ?? ''}
                objectFit="cover"
                layout="fill"
                sizes="600px"
              />
            ) : null}
          </Box>

          {/* tablet */}
          <Box
            className="tablet"
            width="100%"
            height="100%"
            position="absolute"
          >
            {imageTablet ? (
              <Image
                src={imageTablet?.url ?? ''}
                alt={imageTablet?.title ?? ''}
                objectFit="cover"
                layout="fill"
                sizes="900px"
              />
            ) : null}
          </Box>

          {/* desktop */}
          <Box
            className="desktop"
            width="100%"
            height="100%"
            position="absolute"
          >
            {image ? (
              <Image
                src={image?.url ?? ''}
                alt={image?.title ?? ''}
                objectFit="cover"
                layout="fill"
                sizes="1920px"
              />
            ) : null}
          </Box>

          <Anchor href={link} />

          <IconButton
            sx={{
              color: closeButtonColor,
              marginRight: 2,
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </ImageContainer>
      </StyledBox>
    </Portal>
  );
};

export default TopBanner;
