// If there is a start condition and the start time has elapsed
export const isStart = (startTime?: string | null) => {
  if (!startTime) {
    return true;
  }

  const nowMs = new Date().getTime();
  const startMs = new Date(startTime).getTime();

  if (nowMs >= startMs) {
    return true;
  }

  return false;
};

// If there is a end condition and the end time has elapsed
export const isEnd = (endTime?: string | null) => {
  if (!endTime) {
    return false;
  }

  const nowMs = new Date().getTime();
  const endMs = new Date(endTime).getTime();

  if (nowMs >= endMs) {
    return true;
  }

  return false;
};
