import React from 'react';
import { Box } from '@mui/material';
import Layout from 'components/global/Layout';
import TopBanner from 'components/landing/banners/TopBanner';
import PopupDialog from 'components/landing/dialogs/PopupDialog';
import ButtonStickyFooter from 'components/landing/sticky-footers/ButtonStickyFooter';
import CtaStickyFooter from 'components/landing/sticky-footers/CtaStickyFooter';
import TimeCtaStickyFooter from 'components/landing/sticky-footers/TimeCtaStickyFooter';
import PageSections from 'components/pages/PageSections';
import PageSeo from 'components/pages/PageSeo';
import { getHeaderItems } from 'utils/getHeaderItems';
import {
  getDialogPopupById,
  getFooterHtmlDescription,
  getPageAndSectionsByPathname,
  getPages,
  getStickyFooterButtonById,
  getStickyFooterCtaById,
  getStickyFooterTimeCtaById,
  getTopBannerById,
} from 'utils/pages';

import type {
  BannerTopQuery,
  DialogPopupQuery,
  PageQuery,
  StickyFooterButtonQuery,
  StickyFooterCtaQuery,
  StickyFooterTimeCtaQuery,
} from 'graphql/generated';
import type { GetStaticPaths, GetStaticProps, NextPage } from 'next';
import type { LandingSection } from 'types/landing';
import type { HeaderItem } from 'utils/getHeaderItems';

interface LandingProps {
  page: PageQuery['page'];
  headerItems: HeaderItem[];
  topBanner: BannerTopQuery['bannerTop'] | null;
  dialog: DialogPopupQuery['dialogPopup'] | null;
  sections: LandingSection[];
  stickyFooter:
    | StickyFooterCtaQuery['stickyFooterCta']
    | StickyFooterTimeCtaQuery['stickyFooterTimeCta']
    | StickyFooterButtonQuery['stickyFooterButton']
    | null;
  footer: {
    description: string;
  };
}

export const getStaticPaths: GetStaticPaths = async () => {
  /** find all page */
  const pages = await getPages();

  /** define available page */
  const paths = pages.map(item => {
    const pathWords = item?.pathname?.split('/').filter(word => word) ?? [];
    return {
      params: {
        // https://nextjs.org/docs/api-reference/data-fetching/get-static-paths#paths
        slug: pathWords,
      },
    };
  });

  return {
    paths: paths,
    fallback: false,
  };
};

export const getStaticProps: GetStaticProps<LandingProps> = async context => {
  let topBanner: BannerTopQuery['bannerTop'] | null = null;
  let dialog: DialogPopupQuery['dialogPopup'] | null = null;
  let stickyFooter:
    | StickyFooterCtaQuery['stickyFooterCta']
    | StickyFooterTimeCtaQuery['stickyFooterTimeCta']
    | StickyFooterButtonQuery['stickyFooterButton']
    | null = null;

  /**
   * If there is an error inside getStaticProps when handling background regeneration,
   * or you manually throw an error, the last successfully generated page will continue to show.
   * https://nextjs.org/docs/basic-features/data-fetching/incremental-static-regeneration#error-handling-and-revalidation
   */
  const headerItems = await getHeaderItems();

  const slug = context.params?.slug ?? [];

  if (typeof slug === 'string') {
    throw new Error('Invalid slug.');
  }

  const { page, sections } = await getPageAndSectionsByPathname(
    `/${slug.join('/')}`
  );

  /** add banner if exist */
  if (page.banner) {
    topBanner = await getTopBannerById(page.banner.sys.id);
  }

  /** add dialog if exist */
  if (page.dialog) {
    dialog = await getDialogPopupById(page.dialog.sys.id);
  }

  /** add sticky footer cta if exist */
  if (page.stickyFooter?.__typename === 'StickyFooterCta') {
    stickyFooter = await getStickyFooterCtaById(page.stickyFooter.sys.id);
  }

  /** add sticky footer time cta if exist */
  if (page.stickyFooter?.__typename === 'StickyFooterTimeCta') {
    stickyFooter = await getStickyFooterTimeCtaById(page.stickyFooter.sys.id);
  }

  /** add sticky footer button if exist */
  if (page.stickyFooter?.__typename === 'StickyFooterButton') {
    stickyFooter = await getStickyFooterButtonById(page.stickyFooter.sys.id);
  }

  const footerDescription = await getFooterHtmlDescription();

  return {
    props: {
      page,
      headerItems,
      topBanner,
      dialog,
      sections,
      stickyFooter,
      footer: {
        description: footerDescription,
      },
    },
  };
};

const Landing: NextPage<LandingProps> = ({
  page,
  headerItems,
  topBanner,
  dialog,
  sections,
  stickyFooter,
  footer,
}) => {
  const getLayoutPaddingBottom = () => {
    switch (stickyFooter?.__typename) {
      case 'StickyFooterButton':
        return '60px';

      case 'StickyFooterCta':
      case 'StickyFooterTimeCta':
        return '78px';

      default:
        return '0';
    }
  };

  return (
    <Layout
      headerItems={headerItems}
      paddingBottom={getLayoutPaddingBottom()}
      hasTopBanner={!!topBanner}
      footer={footer}
    >
      <PageSeo page={page} />

      <Box>
        {/* render header section */}
        {topBanner ? (
          <TopBanner
            id={topBanner.sys.id}
            image={topBanner?.image ?? undefined}
            imageTablet={topBanner?.imageTablet ?? undefined}
            imageMobile={topBanner?.imageMobile ?? undefined}
            link={topBanner?.link ?? ''}
            closeButtonColor={topBanner?.closeButtonColor ?? undefined}
          />
        ) : null}

        {/* render sections */}
        <PageSections sections={sections} />
      </Box>

      {dialog ? (
        <PopupDialog
          id={dialog.sys.id}
          imageUrl={dialog?.image?.url ?? ''}
          imageTitle={dialog?.image?.title ?? ''}
          startTime={dialog?.startTime}
          endTime={dialog?.endTime}
        />
      ) : null}

      {/* sticky footer */}
      {stickyFooter?.__typename === 'StickyFooterCta' ? (
        <CtaStickyFooter
          title={stickyFooter?.title ?? ''}
          description={stickyFooter?.description ?? ''}
          buttonTitle={stickyFooter?.buttonTitle ?? ''}
          buttonLink={stickyFooter?.buttonLink ?? ''}
          startTime={stickyFooter?.startTime}
          endTime={stickyFooter?.endTime}
        />
      ) : null}
      {stickyFooter?.__typename === 'StickyFooterTimeCta' ? (
        <TimeCtaStickyFooter
          title={stickyFooter?.title ?? ''}
          buttonTitle={stickyFooter?.buttonTitle ?? ''}
          buttonLink={stickyFooter?.buttonLink ?? ''}
          startTime={stickyFooter?.startTime}
          endTime={stickyFooter?.endTime}
        />
      ) : null}
      {stickyFooter?.__typename === 'StickyFooterButton' ? (
        <ButtonStickyFooter
          title={stickyFooter?.title ?? ''}
          link={stickyFooter?.link ?? ''}
        />
      ) : null}
    </Layout>
  );
};

export default Landing;
