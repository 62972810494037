import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';

interface ButtonStickyFooterProps {
  title: string;
  link: string;
}

const ButtonStickyFooter: React.FC<ButtonStickyFooterProps> = ({
  title,
  link,
}) => {
  const theme = useTheme();

  return (
    <Box position="fixed" zIndex={10} bottom={0} left={0} width="100%">
      <ButtonBase
        href={link}
        sx={{
          bgcolor: theme.palette.primary.main,
          width: '100%',
          height: '100%',
          py: 2,
        }}
      >
        <Typography color="white" variant="subtitle1" fontWeight={600}>
          {title}
        </Typography>
      </ButtonBase>
    </Box>
  );
};

export default ButtonStickyFooter;
