export const moveUpGently = (order = 0) => {
  return {
    initial: { opacity: 0, y: 30 },
    whileInView: { opacity: 1, y: 0 },
    viewport: { once: true },
    transition: {
      duration: 0.5,
      delay: 0.3 * order,
    },
  };
};

export const popUp = (order = 0) => {
  return {
    initial: {
      opacity: 0,
      y: 50,
    },
    whileInView: {
      opacity: 1,
      y: 0,
    },
    viewport: {
      once: true,
    },
    transition: {
      duration: 0.4,
      type: 'spring',
      stiffness: 80,
      delay: 0.2 * order,
    },
  };
};

// wrap animation element for animating in turn.
export const popUpContainer = {
  variants: {
    hidden: {},
    visible: {
      transition: {
        delayChildren: 0,
        staggerChildren: 0.1,
      },
    },
  },
  initial: 'hidden',
  whileInView: 'visible',
  viewport: { once: true },
};

// wrapped element that is animated. use with popUpContainer.
export const popUpItem = {
  variants: {
    hidden: { y: 30, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.4,
        type: 'spring',
        stiffness: 80,
      },
    },
  },
};
