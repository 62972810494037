import React from 'react';
import { NextSeo } from 'next-seo';

import type { PageQuery } from 'graphql/generated';

interface PageSeoProps {
  page: PageQuery['page'];
}

const PageSeo: React.FC<PageSeoProps> = ({ page }) => {
  const seo = page?.seo;

  const getTwitter = () => {
    const site = page?.seo?.twitter?.site;
    const creator = page?.seo?.twitter?.creator;

    if (site || creator) {
      return {
        cardType: 'summary_large_image',
        site,
        handle: creator,
      };
    }
  };

  return (
    <NextSeo
      title={seo?.title ?? ''}
      description={seo?.description ?? ''}
      canonical={seo?.url ?? ''}
      openGraph={{
        type: 'website',
        title: seo?.title ?? '',
        description: seo?.description ?? '',
        url: seo?.url ?? '',
        images: [
          {
            url: seo?.image?.url ?? '',
          },
        ],
      }}
      twitter={getTwitter()}
    />
  );
};

export default PageSeo;
