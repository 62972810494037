import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { moveUpGently } from 'lib/animation';
import { Colors } from 'styles/colors';

interface SectionHeaderProps {
  title: string;
  description?: string;
  titleAlign?: string;
  descriptionAlign?: string;
  titleColor?: string;
  descriptionColor?: string;
  mb?: number;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  description,
  titleAlign = 'start',
  descriptionAlign = 'start',
  titleColor,
  descriptionColor = Colors.grey7,
  mb,
}) => {
  return (
    <Box display="flex" flexDirection="column" gap={1} mb={mb}>
      <Typography
        variant="h3"
        sx={{
          whiteSpace: 'pre-line',
          textAlign: titleAlign,
        }}
        color={titleColor}
        component={motion.h3}
        {...moveUpGently(0)}
      >
        {title}
      </Typography>
      {description ? (
        <Typography
          variant="body1"
          sx={{ whiteSpace: 'pre-line', textAlign: descriptionAlign }}
          color={descriptionColor}
          component={motion.p}
          {...moveUpGently(0)}
        >
          {description}
        </Typography>
      ) : null}
    </Box>
  );
};

export default SectionHeader;
