import React from 'react';
import styled from '@emotion/styled';
import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { motion } from 'framer-motion';
import { popUpContainer, popUpItem } from 'lib/animation';
import Image from 'next/image';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import type { SxProps } from '@mui/material';

export type HeroSectionItem = {
  sys: {
    id: string;
  };
  title?: string | null;
  description?: string | null;
  titleColor?: string | null;
  descriptionColor?: string | null;
  titleAlign?: string | null;
  descriptionAlign?: string | null;
  image?: {
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
  } | null;
  imageTablet?: {
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
  } | null;
  imageMobile?: {
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
  } | null;
  video?: {
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
  } | null;
  videoTablet?: {
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
  } | null;
  videoMobile?: {
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
  } | null;
  link?: string | null;
};

interface HeroSectionProps {
  items: (HeroSectionItem | null)[];
}

const StyledSwiper = styled(Swiper)<any>(({ theme }) => ({
  '.swiper-pagination-bullets': {
    left: '32px !important',
    bottom: '32px !important',
    width: 'unset !important',
  },
  '.swiper-pagination-bullet': {
    width: 12,
    height: 12,
    borderRadius: 6,
  },
  '.swiper-pagination-bullet-active': {
    width: 48,
    backgroundColor: '#fff',
  },
  [theme.breakpoints.down('md')]: {
    '.swiper-pagination-bullets': {
      left: '24px !important',
      bottom: '24px !important',
    },
  },
}));

interface HeroSectionImageProps {
  url?: string | null;
  title?: string | null;
  width?: number | null;
  height?: number | null;
  sx: SxProps;
}

const HeroSectionImage: React.FC<HeroSectionImageProps> = ({
  url,
  title,
  width,
  height,
  sx,
}) => {
  // to prevent swiper right padding bug
  // when resize event is emitted, swiper content width is resized
  // https://github.com/nolimits4web/swiper/issues/2218#issuecomment-388837042
  const handleLoad = () => {
    window.dispatchEvent(new Event('resize'));
  };

  return (
    <Box
      // this flex option remove bottom white space
      display="flex"
      justifyContent="center"
      width="100.1%"
      maxWidth="100.1%"
      margin="-1px"
      sx={{ ...sx, aspectRatio: `${width} / ${height}` }}
    >
      <Image src={url ?? ''} alt={title ?? ''} fill onLoad={handleLoad} />
    </Box>
  );
};

interface HeroSectionVideoProps {
  url?: string | null;
  sx: SxProps;
}

const StyledVideo = styled.video`
  display: block;
  width: 100%;
`;

const HeroSectionVideo: React.FC<HeroSectionVideoProps> = ({ url, sx }) => {
  // to prevent swiper right padding bug
  // when resize event is emitted, swiper content width is resized
  // https://github.com/nolimits4web/swiper/issues/2218#issuecomment-388837042
  const handleLoadedData = () => {
    window.dispatchEvent(new Event('resize'));
  };

  return (
    <Box sx={sx}>
      <StyledVideo
        autoPlay
        muted
        loop
        // https://stackoverflow.com/questions/43570460/html5-video-autoplay-on-iphone
        playsInline
        onLoadedData={handleLoadedData}
      >
        <source src={url ?? ''} />
      </StyledVideo>
    </Box>
  );
};

interface HeroSectionTextContentProps {
  item: HeroSectionItem | null;
}

const getTextAlign = (align?: string | null) => {
  if (align === 'start' || align === 'center' || align === 'end') {
    return align;
  }
  return 'center';
};

const HeroSectionTextContent: React.FC<HeroSectionTextContentProps> = ({
  item,
}) => {
  if (!item?.title && !item?.description && !item?.link) {
    return null;
  }

  return (
    <Box
      position="absolute"
      width="100%"
      height="100%"
      zIndex={1}
      component={item?.link ? 'a' : 'div'}
      href={item?.link ?? undefined}
    >
      <Container
        maxWidth="lg"
        sx={{
          px: 3,
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: 1,
          height: '100%',
        }}
        component={motion.div}
        {...popUpContainer}
      >
        {item?.title ? (
          <Typography
            variant="h2"
            zIndex={1}
            textAlign={getTextAlign(item?.titleAlign)}
            color={item?.titleColor || 'inherit'}
            sx={{
              whiteSpace: 'pre-line',
            }}
            component={motion.h2}
            {...popUpItem}
          >
            {item?.title}
          </Typography>
        ) : null}
        {item?.description ? (
          <Typography
            variant="body1"
            zIndex={1}
            textAlign={getTextAlign(item?.descriptionAlign)}
            color={item?.descriptionColor || 'inherit'}
            sx={{
              whiteSpace: 'pre-line',
            }}
            component={motion.p}
            {...popUpItem}
          >
            {item?.description}
          </Typography>
        ) : null}
      </Container>
    </Box>
  );
};

const desktopSx: Readonly<SxProps> = {
  display: {
    xs: 'none',
    sm: 'none',
    md: 'flex',
  },
};

const tabletSx: Readonly<SxProps> = {
  display: {
    xs: 'none',
    sm: 'flex',
    md: 'none',
  },
};

const mobileSx: Readonly<SxProps> = {
  display: {
    xs: 'flex',
    sm: 'none',
    md: 'none',
  },
};

const HeroSection: React.FC<HeroSectionProps> = ({ items }) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const getAutoplay = () => {
    if (items.length <= 1) {
      return;
    }

    return {
      delay: 5000,
      disableOnInteraction: false,
    };
  };

  return (
    <Box component="section">
      <StyledSwiper
        modules={[Pagination, Autoplay]}
        slidesPerView={1}
        tabIndex={1}
        autoplay={getAutoplay()}
        loop={items.length > 1}
        pagination={{
          clickable: items.length > 1,
        }}
        allowTouchMove={items.length > 1}
      >
        {items.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <HeroSectionTextContent item={item} />

              {/* desktop */}
              {item?.image ? (
                <HeroSectionImage
                  url={item.image.url}
                  title={item.image.title}
                  width={item.image.width}
                  height={item.image.height}
                  sx={desktopSx}
                />
              ) : item?.video && isDesktop ? (
                <HeroSectionVideo url={item.video.url} sx={desktopSx} />
              ) : null}

              {/* tablet */}
              {item?.imageTablet ? (
                <HeroSectionImage
                  url={item.imageTablet.url}
                  title={item.imageTablet.title}
                  width={item.imageTablet.width}
                  height={item.imageTablet.height}
                  sx={tabletSx}
                />
              ) : item?.videoTablet && isTablet ? (
                <HeroSectionVideo url={item.videoTablet.url} sx={tabletSx} />
              ) : null}

              {/* mobile */}
              {item?.imageMobile ? (
                <HeroSectionImage
                  url={item.imageMobile.url}
                  title={item.imageMobile.title}
                  width={item.imageMobile.width}
                  height={item.imageMobile.height}
                  sx={mobileSx}
                />
              ) : item?.videoMobile && isMobile ? (
                <HeroSectionVideo url={item.videoMobile.url} sx={mobileSx} />
              ) : null}
            </SwiperSlide>
          );
        })}
      </StyledSwiper>
    </Box>
  );
};

export default HeroSection;
