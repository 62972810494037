import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton } from '@mui/material';
import getYouTubeID from 'get-youtube-id';
import { Colors } from 'styles/colors';

interface YoutubeModalProps {
  youtubeUrl: string;
  onClose: () => void;
}

const getAspectRatioStyleWithFallback = (aspectRatio: number) => {
  return css`
    aspect-ratio: ${aspectRatio};

    @supports not (aspect-ratio: 1) {
      &::before {
        float: left;
        padding-top: ${100 / aspectRatio}%;
        content: '';
      }

      &::after {
        display: block;
        content: '';
        clear: both;
      }
    }
  `;
};

const StyledModal = styled.div`
  max-width: 60rem;
  margin: auto;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 981;

  ${getAspectRatioStyleWithFallback(1.5)}
`;

const StyledIframeContainer = styled.div`
  ${getAspectRatioStyleWithFallback(1.5)}
`;

const StyledBackdrop = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #00000080;
  z-index: 980;
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
  display: block;
`;

const YoutubeModal: React.FC<YoutubeModalProps> = ({ youtubeUrl, onClose }) => {
  const youtubeId = getYouTubeID(youtubeUrl);

  React.useEffect(() => {
    const keydownEventListener = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', keydownEventListener);

    return () => {
      window.removeEventListener('keydown', keydownEventListener);
    };
  }, [onClose]);

  return (
    <StyledBackdrop
      onClick={e => {
        e.stopPropagation();
        onClose();
      }}
    >
      <StyledModal>
        <Box display="flex" justifyContent="flex-end">
          <IconButton onClick={onClose}>
            <CloseIcon fontSize="large" sx={{ color: Colors.white }} />
          </IconButton>
        </Box>
        <StyledIframeContainer>
          <StyledIframe
            allowFullScreen
            src={
              youtubeId
                ? `https://youtube.com/embed/${youtubeId}?autoplay=1&mute=1`
                : undefined
            }
          />
        </StyledIframeContainer>
      </StyledModal>
    </StyledBackdrop>
  );
};

export default YoutubeModal;
