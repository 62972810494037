import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { motion } from 'framer-motion';
import { popUpContainer, popUpItem } from 'lib/animation';
import { Colors } from 'styles/colors';

import SectionHeader from './shared/SectionHeader';

type NumericalCardProps = {
  sys: {
    id: string;
  };
  title?: {
    label?: string | null;
    color?: string | null;
  } | null;
  description?: {
    label?: string | null;
    color?: string | null;
  } | null;
  value?: {
    label?: string | null;
    color?: string | null;
  } | null;
  unit?: {
    label?: string | null;
    color?: string | null;
  } | null;
};

interface NumericalCardSectionProps {
  title: string;
  description: string;
  titleAlign?: string;
  descriptionAlign?: string;
  titleColor?: string;
  descriptionColor?: string;
  backgroundColor?: string;
  cards: (NumericalCardProps | null)[];
}

const NumericalCard: React.FC<Omit<NumericalCardProps, 'sys'>> = ({
  title,
  description,
  value,
  unit,
}) => {
  if (!value) return null;

  const getDecimals = (value: string) => {
    const [, decimal] = value.split('.');
    if (decimal) {
      return decimal.length;
    }
    return 0;
  };

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography variant="subtitle2" color={title?.color ?? Colors.black}>
        {title?.label ?? ''}
      </Typography>

      <Typography>
        {/* https://stackoverflow.com/questions/51044090/react-countup-animation-starts-immediately-after-the-page-loading-should-start */}
        <Typography
          component="span"
          variant="h3"
          color={value?.color ?? 'primary'}
        >
          <CountUp
            start={0}
            end={Number(value?.label ?? '')}
            separator=","
            duration={1}
            decimals={getDecimals(value?.label ?? '')}
          >
            {({ countUpRef, start }) => (
              <VisibilitySensor onChange={start} delayedCall>
                <span ref={countUpRef} />
              </VisibilitySensor>
            )}
          </CountUp>
        </Typography>
        <Typography
          component="span"
          variant="h3"
          color={unit?.color ?? 'primary'}
        >
          {unit?.label ?? ''}
        </Typography>
      </Typography>
      {description ? (
        <Typography
          variant="body1"
          sx={{ whiteSpace: 'pre-line' }}
          color={description?.color ?? Colors.grey7}
        >
          {description?.label}
        </Typography>
      ) : null}
    </Box>
  );
};

const NumericalCardSection: React.FC<NumericalCardSectionProps> = ({
  title,
  description,
  titleAlign,
  descriptionAlign,
  titleColor,
  descriptionColor,
  backgroundColor,
  cards,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box component="section" sx={{ bgcolor: backgroundColor }}>
      <Container
        maxWidth="lg"
        sx={{
          py: {
            xs: 10,
            sm: 12.5,
            md: 15,
          },
          px: 3,
        }}
      >
        <SectionHeader
          title={title}
          description={description}
          titleAlign={titleAlign}
          descriptionAlign={descriptionAlign}
          titleColor={titleColor}
          descriptionColor={descriptionColor}
          mb={4}
        />
        <Box
          display="grid"
          gap={3}
          gridTemplateColumns={isMobile ? '1fr' : 'repeat(3, 1fr)'}
          component={motion.div}
          {...popUpContainer}
        >
          {cards.map(card => (
            <Box key={card?.sys.id} component={motion.div} {...popUpItem}>
              <NumericalCard
                title={card?.title}
                description={card?.description}
                value={card?.value}
                unit={card?.unit}
              />
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default NumericalCardSection;
