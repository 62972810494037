import React from 'react';
import {
  Box,
  Button,
  Portal,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Colors } from 'styles/colors';
import { isEnd, isStart } from 'utils/times';

interface TimeCtaStickyFooterProps {
  title: string;
  buttonTitle: string;
  buttonLink: string;
  startTime?: string | null;
  endTime?: string | null;
}

const TimeCtaStickyFooterContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Portal>
      <Box
        position="fixed"
        bottom={0}
        left={0}
        width="100%"
        px={{
          xs: 0,
          sm: 0,
          md: 3,
        }}
        display="flex"
        justifyContent="center"
        maxWidth="lg"
        zIndex={10}
        sx={{
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        {children}
      </Box>
    </Portal>
  );
};

const TimeCtaStickyFooter: React.FC<TimeCtaStickyFooterProps> = ({
  title,
  buttonTitle,
  buttonLink,
  startTime,
  endTime,
}) => {
  const theme = useTheme();
  const isTabletOrSmaller = useMediaQuery(theme.breakpoints.down('md'));

  const [renderedCount, setRenderedCount] = React.useState(0);

  React.useEffect(() => {
    let timer: NodeJS.Timeout | undefined;

    if (endTime) {
      timer = setInterval(() => {
        const nowMs = new Date().getTime();
        const endMs = new Date(endTime).getTime();
        const duration = endMs - nowMs;

        // if arrived to 0, clear interval
        if (duration <= 0) {
          if (timer) {
            clearInterval(timer);
          }
        } else {
          setRenderedCount(count => count + 1);
        }
      }, 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [endTime]);

  const nowMs = new Date().getTime();
  const endMs = endTime ? new Date(endTime).getTime() : 0;
  const duration = endMs - nowMs;

  if (duration < 0) {
    return null;
  }

  const convertMsToDate = (duration: number) => {
    const durationMiliseconds = duration;
    const milliseconds = durationMiliseconds % 1000;

    const durationSeconds = Math.floor(duration / 1000);
    const seconds = durationSeconds % 60;

    const durationMinutes = Math.floor(durationSeconds / 60);
    const minutes = durationMinutes % 60;

    const durationHours = Math.floor(durationMinutes / 60);
    const hours = durationHours % 24;

    const date = Math.floor(durationHours / 24);

    return {
      milliseconds,
      seconds,
      minutes,
      hours,
      date,
    };
  };

  const { seconds, minutes, hours, date } = convertMsToDate(
    duration < 0 ? 0 : duration
  );

  if (isStart(startTime) && !isEnd(endTime)) {
    return (
      <TimeCtaStickyFooterContainer>
        <Box
          key={renderedCount}
          component="footer"
          display="flex"
          justifyContent="space-between"
          px={3}
          py={2}
          flexGrow={1}
          bgcolor="white"
          sx={{
            borderTopLeftRadius: isTabletOrSmaller ? 0 : theme.spacing(2),
            borderTopRightRadius: isTabletOrSmaller ? 0 : theme.spacing(2),
            borderTop: 1,
            borderLeft: isTabletOrSmaller ? 0 : 1,
            borderRight: isTabletOrSmaller ? 0 : 1,
            borderColor: Colors.grey3,
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            gap={0.5}
          >
            <Typography variant="caption">{title}</Typography>
            <Box>
              <Typography
                variant="body1"
                fontWeight={600}
                sx={{
                  whiteSpace: {
                    xs: 'pre-line',
                    sm: 'unset',
                    md: 'unset',
                  },
                }}
              >
                혜택 종료까지{' '}
                <Typography
                  component="span"
                  variant="body1"
                  fontWeight={600}
                  color={theme.palette.primary.main}
                >
                  {date > 0 && `${date}일 `}
                  {hours > 0 && `${hours}시간 `}
                  {minutes > 0 && `${minutes}분 `}
                  {seconds > 0 && `${seconds}초`}
                </Typography>
                {'\n'}
                남았습니다.
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" flexShrink={0}>
            <Button
              variant="contained"
              href={buttonLink}
              disableElevation
              size="large"
            >
              {buttonTitle}
            </Button>
          </Box>
        </Box>
      </TimeCtaStickyFooterContainer>
    );
  }

  return null;
};

export default TimeCtaStickyFooter;
