import React from 'react';
import { Box, Grid, styled } from '@mui/material';
import { alpha } from '@mui/material';
import DOMPurify from 'isomorphic-dompurify';
import { Colors } from 'styles/colors';

import type { BoxProps } from '@mui/material';

interface GlobalFooterProps {
  paddingBottom?: BoxProps['paddingBottom'];
  footer?: {
    description: string;
  };
}

const StyledMarkdownBox = styled(Box)(({ theme }) => ({
  p: {
    marginBottom: theme.spacing(1),
  },
  'p:last-of-type': {
    marginBottom: 0,
  },
  color: alpha(Colors.white, 0.7),
  fontSize: theme.typography.body2.fontSize,
}));

const GlobalFooter: React.FC<GlobalFooterProps> = ({
  paddingBottom,
  footer,
}) => {
  return (
    <Box
      component="footer"
      style={{ background: Colors.blueGray8 }}
      paddingBottom={paddingBottom}
    >
      {/* Need to change "maxWidth" with mui specification */}
      <Grid
        container
        justifyContent="center"
        padding="0 1.5rem 0"
        marginLeft="auto"
        marginRight="auto"
        maxWidth="77rem"
      >
        <Grid item xs={12}>
          <Grid container padding="2.25rem 0" rowGap={2}>
            {footer ? (
              <Grid item xs={12}>
                <Grid container direction="column" flexDirection="column">
                  <Grid item>
                    <StyledMarkdownBox
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(footer.description),
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GlobalFooter;
