import styled from '@emotion/styled';
import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import { popUpContainer, popUpItem } from 'lib/animation';
import Image from 'next/image';

import SectionHeader from './shared/SectionHeader';

type ImageCard = {
  sys: {
    id: string;
  };
  image?: {
    url?: string | null;
    title?: string | null;
  } | null;
  imageMobile?: {
    url?: string | null;
    title?: string | null;
  } | null;
  link?: string | null;
};

interface ImageCardSectionProps {
  title: string;
  description: string;
  titleAlign?: string;
  descriptionAlign?: string;
  titleColor?: string;
  descriptionColor?: string;
  backgroundColor?: string;
  cards: (ImageCard | null)[];
}

const StyledImage = styled(Image)(({ theme }: any) => ({
  borderRadius: theme.shape.borderRadius * 6,
}));

const StyledMobileImage = styled(Image)(({ theme }: any) => ({
  borderRadius: theme.shape.borderRadius * 6,
  width: '70vw',
  flexShrink: 0,
}));

const StyledBox = styled(Box)`
  /* hidden scrollbar  */
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  > div:first-of-type,
  > a:first-of-type {
    margin-left: 24px;
  }

  > div:last-of-type,
  > a:last-of-type {
    margin-right: 24px;
  }
`;

const ImageCardSection: React.FC<ImageCardSectionProps> = ({
  title,
  description,
  titleAlign = 'start',
  descriptionAlign = 'start',
  titleColor,
  descriptionColor,
  backgroundColor,
  cards,
}) => {
  const theme = useTheme();
  const isMobileOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));
  const isTabletOrSmaller = useMediaQuery(theme.breakpoints.down('md'));

  if (isTabletOrSmaller) {
    return (
      <Box component="section" py={10} sx={{ bgcolor: backgroundColor }}>
        <Box px={3}>
          <SectionHeader
            title={title}
            description={description}
            titleAlign={titleAlign}
            descriptionAlign={descriptionAlign}
            titleColor={titleColor}
            descriptionColor={descriptionColor}
            mb={4}
          />
        </Box>
        <StyledBox display="flex" gap={3}>
          {cards.map((card, index) => (
            <Box
              component="a"
              href={card?.link ?? undefined}
              key={index}
              width={isMobileOrSmaller ? '65vw' : '40vw'}
              flexShrink={0}
              position="relative"
              sx={{
                aspectRatio: '7 / 9',
              }}
            >
              <StyledMobileImage
                key={index}
                src={card?.imageMobile?.url ?? ''}
                alt={card?.imageMobile?.title ?? ''}
                layout="fill"
                objectFit="cover"
                sizes="600px"
              />
            </Box>
          ))}
        </StyledBox>
      </Box>
    );
  }

  return (
    <Box component="section" sx={{ bgcolor: backgroundColor }}>
      <Container
        maxWidth="lg"
        sx={{
          py: {
            xs: 10,
            sm: 12.5,
            md: 15,
          },
        }}
      >
        <SectionHeader
          title={title}
          description={description}
          titleAlign={titleAlign}
          descriptionAlign={descriptionAlign}
          titleColor={titleColor}
          descriptionColor={descriptionColor}
          mb={4}
        />
        <Box
          display="grid"
          gridTemplateColumns="repeat(3, 1fr)"
          gap={3}
          component={motion.div}
          {...popUpContainer}
        >
          {cards.map((card, index) => (
            <Box
              key={index}
              position="relative"
              href={card?.link ?? undefined}
              sx={{
                aspectRatio: '1 / 1',
              }}
              component={motion.a}
              {...popUpItem}
            >
              <StyledImage
                src={card?.image?.url ?? ''}
                alt={card?.image?.title ?? ''}
                layout="fill"
                objectFit="cover"
                sizes="333px"
              />
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default ImageCardSection;
