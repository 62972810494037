import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Container, Typography } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { motion } from 'framer-motion';
import { popUpContainer, popUpItem } from 'lib/animation';
import { Colors } from 'styles/colors';

import SectionHeader from './shared/SectionHeader';

type FaqCard = {
  sys: {
    id: string;
  };
  question?: string | null;
  answer?: string | null;
};

interface FaqCardSectionProps {
  title: string;
  description: string;
  titleAlign?: string;
  descriptionAlign?: string;
  titleColor?: string;
  descriptionColor?: string;
  backgroundColor?: string;
  cards: (FaqCard | null)[];
}

const Accordion: React.FC<{
  summary: React.ReactNode;
  details: React.ReactNode;
  isDefaultExpanded?: boolean;
}> = ({ summary, details, isDefaultExpanded = false }) => {
  const [isExpanded, setIsExpanded] = React.useState(isDefaultExpanded);

  const handleContainerClick = () => {
    // all area clickable when closed
    if (!isExpanded) {
      setIsExpanded(true);
    }
  };

  const handleSummaryClick = () => {
    // only summary area clickable when opened
    if (isExpanded) {
      setIsExpanded(false);
    }
  };

  return (
    <MuiAccordion
      expanded={isExpanded}
      onClick={handleContainerClick}
      disableGutters
      elevation={0}
      square
      sx={{
        '&:before': {
          display: 'none',
        },
        border: 1,
        borderColor: Colors.grey3,
        borderRadius: 4,
        boxShadow:
          '2px 2px 24px rgba(0, 0, 0, 0.02), 0px 2px 12px rgba(0, 0, 0, 0.04)',
        pb: 2.5,
        cursor: isExpanded ? 'unset' : 'pointer',
      }}
    >
      <AccordionSummary
        onClick={handleSummaryClick}
        expandIcon={<KeyboardArrowDownIcon />}
        sx={{
          pt: 2.5,
          pb: 0,
          pl: 2.5,
          pr: 2.5,
          '& .MuiAccordionSummary-content': {
            m: 0,
          },
          minHeight: 'unset',
        }}
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails sx={{ px: 2.5, pt: 0.5, pb: 0 }}>
        {details}
      </AccordionDetails>
    </MuiAccordion>
  );
};

const FaqCardSection: React.FC<FaqCardSectionProps> = ({
  title,
  description,
  titleAlign = 'start',
  descriptionAlign = 'start',
  titleColor,
  descriptionColor,
  backgroundColor,
  cards,
}) => {
  return (
    <Box component="section" sx={{ bgcolor: backgroundColor }}>
      <Container
        sx={{
          py: {
            xs: 10,
            sm: 12.5,
            md: 15,
          },
          px: 3,
        }}
      >
        <SectionHeader
          title={title}
          description={description}
          titleAlign={titleAlign}
          descriptionAlign={descriptionAlign}
          titleColor={titleColor}
          descriptionColor={descriptionColor}
          mb={3}
        />
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          component={motion.div}
          {...popUpContainer}
        >
          {cards.map(card => (
            <Box key={card?.sys.id} component={motion.div} {...popUpItem}>
              <Accordion
                summary={
                  <Typography variant="body1" fontWeight={700}>
                    {card?.question}
                  </Typography>
                }
                details={
                  <Typography variant="body2" color={Colors.grey7}>
                    {card?.answer}
                  </Typography>
                }
              />
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default FaqCardSection;
