import React from 'react';
import styled from '@emotion/styled';
import { Box, Container, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { popUpContainer, popUpItem } from 'lib/animation';
import { Colors } from 'styles/colors';

import SectionHeader from './shared/SectionHeader';
import YoutubeModal from './shared/YoutubeModal';

type VideoCard = {
  sys: {
    id: string;
  };
  title?: string | null;
  description?: string | null;
  videoUrl?: string | null;
};

interface VideoCardSectionProps {
  title: string;
  description: string;
  titleAlign?: string;
  descriptionAlign?: string;
  titleColor?: string;
  descriptionColor?: string;
  backgroundColor?: string;
  cards: (VideoCard | null)[];
}

const StyledVideoContainer = styled(Box)`
  overflow: hidden;

  /* aspect ratio */
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
`;

const StyledIframe = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
`;

const CourseInfoYoutubeVideo: React.FC<{
  videoUrl: string;
  title: string;
  description: string;
}> = ({ videoUrl, title, description }) => {
  const [isShowModal, setIsShowModal] = React.useState(false);

  // https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url
  // see Chris Nolet comment
  const parseYoutubeUrlToVideoId = (url: string) => {
    const regExp =
      // eslint-disable-next-line no-useless-escape
      /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;
    const match = regExp.exec(url);
    return match && match[1].length === 11 ? match[1] : '';
  };

  const handleClick = () => {
    setIsShowModal(true);
  };

  return (
    <>
      <Box component="article" position="relative">
        <StyledVideoContainer borderRadius={4} mb={1.5} position="relative">
          {/* video clickable area */}
          <Box
            position="absolute"
            zIndex={1}
            width="100%"
            height="100%"
            bgcolor="transparent"
            sx={{
              cursor: 'pointer',
            }}
            onClick={handleClick}
          />
          {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
          <StyledIframe
            src={`https://www.youtube.com/embed/${parseYoutubeUrlToVideoId(
              videoUrl
            )}`}
            frameBorder="0"
          />
        </StyledVideoContainer>
        <Typography variant="body1" fontWeight={600} mb={0.5}>
          {title}
        </Typography>
        <Typography variant="body2" color={Colors.grey7}>
          {description}
        </Typography>
      </Box>

      {isShowModal ? (
        <YoutubeModal
          youtubeUrl={videoUrl}
          onClose={() => setIsShowModal(false)}
        />
      ) : null}
    </>
  );
};

const VideoCardSection: React.FC<VideoCardSectionProps> = ({
  title,
  description,
  titleAlign = 'start',
  descriptionAlign = 'start',
  titleColor,
  descriptionColor,
  backgroundColor,
  cards,
}) => {
  return (
    <Box component="section" bgcolor={backgroundColor}>
      <Container
        maxWidth="lg"
        sx={{
          py: {
            xs: 10,
            sm: 12.5,
            md: 15,
          },
          px: 3,
        }}
      >
        <SectionHeader
          title={title}
          description={description}
          titleAlign={titleAlign}
          descriptionAlign={descriptionAlign}
          titleColor={titleColor}
          descriptionColor={descriptionColor}
          mb={3}
        />
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: '1fr',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
          }}
          gap={{
            xs: 2,
            sm: 3,
            md: 3,
          }}
          component={motion.div}
          {...popUpContainer}
        >
          {cards.map(card => (
            <Box key={card?.sys.id} component={motion.div} {...popUpItem}>
              <CourseInfoYoutubeVideo
                videoUrl={card?.videoUrl ?? ''}
                title={card?.title ?? ''}
                description={card?.description ?? ''}
              />
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default VideoCardSection;
