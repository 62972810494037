import { Box, Card, Container, Typography, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import { popUpContainer, popUpItem } from 'lib/animation';
import { Colors } from 'styles/colors';

import SectionHeader from './shared/SectionHeader';

type SurveyCard = {
  sys: {
    id: string;
  };
  title?: string | null;
  percent?: number | null;
};

interface SurveyCardSectionProps {
  title: string;
  description: string;
  titleAlign?: string;
  descriptionAlign?: string;
  titleColor?: string;
  descriptionColor?: string;
  backgroundColor?: string;
  cards: (SurveyCard | null)[];
}

const SurveyCard: React.FC<{ title?: string; percent?: number }> = ({
  title,
  percent,
}) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        p: 2.5,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        borderRadius: 4,
        boxShadow:
          '2px 2px 24px rgba(0, 0, 0, 0.02), 0px 2px 12px rgba(0, 0, 0, 0.04)',
      }}
      elevation={0}
    >
      <Typography variant="subtitle2">{title}</Typography>

      {/* prgress bar */}
      <Box
        borderRadius={2}
        bgcolor={Colors.blueGray2}
        height={30}
        overflow="hidden"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            bgcolor: theme.palette.primary.main,
            width: `${percent ?? 0}%`,
            height: '100%',
          }}
        >
          <Typography color="white">{`${percent ?? 0}%`}</Typography>
        </Box>
      </Box>
    </Card>
  );
};

const SurveyCardSection: React.FC<SurveyCardSectionProps> = ({
  title,
  descriptionAlign,
  titleAlign = 'start',
  description = 'start',
  titleColor,
  descriptionColor,
  backgroundColor,
  cards,
}) => {
  return (
    <Box component="section" sx={{ bgcolor: backgroundColor }}>
      <Container
        maxWidth="lg"
        sx={{
          py: {
            xs: 10,
            sm: 12.5,
            md: 15,
          },
          px: 3,
        }}
      >
        <SectionHeader
          title={title}
          description={description}
          titleAlign={titleAlign}
          descriptionAlign={descriptionAlign}
          titleColor={titleColor}
          descriptionColor={descriptionColor}
          mb={4}
        />
        <Box
          gap={{
            xs: 2,
            sm: 3,
            md: 3,
          }}
          display="grid"
          gridTemplateColumns={{
            xs: '1fr',
            sm: '1fr 1fr',
            md: '1fr 1fr',
          }}
          component={motion.div}
          {...popUpContainer}
        >
          {cards.map(card => (
            <Box key={card?.sys.id} component={motion.div} {...popUpItem}>
              <SurveyCard
                title={card?.title ?? ''}
                percent={card?.percent ?? 0}
              />
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default SurveyCardSection;
