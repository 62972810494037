export const setCookie = (cname: string, cvalue: string) => {
  if (typeof window !== 'undefined') {
    // till today midnight
    const date = new Date();
    date.setTime(date.setHours(23, 59, 59, 999));
    date.toUTCString();
    const expires = 'expires=' + date.toUTCString();
    window.document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }
};
