import { Box, Container, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { moveUpGently } from 'lib/animation';
import { Colors } from 'styles/colors';

import SectionHeader from './shared/SectionHeader';

interface QuoteSectionProps {
  title?: string;
  titleAlign?: string;
  titleColor?: string;
  content: string;
  contentAlign?: string;
  contentColor?: string;
  backgroundColor?: string;
}

const QuoteSection: React.FC<QuoteSectionProps> = ({
  title,
  titleAlign,
  titleColor,
  content,
  contentAlign = 'start',
  contentColor = Colors.grey7,
  backgroundColor,
}) => {
  return (
    <Box component="section" bgcolor={backgroundColor}>
      <Container
        maxWidth="lg"
        sx={{
          py: {
            xs: 7.5,
            sm: 10,
            md: 12.5,
          },
          px: 3,
        }}
      >
        {title ? (
          <SectionHeader
            title={title}
            titleAlign={titleAlign}
            titleColor={titleColor}
            mb={3}
          />
        ) : null}
        <Typography
          color={contentColor}
          fontWeight={700}
          sx={{ whiteSpace: 'pre-line', textAlign: contentAlign }}
          component={motion.p}
          {...moveUpGently(0)}
        >
          {content}
        </Typography>
      </Container>
    </Box>
  );
};

export default QuoteSection;
