import { Box, Container } from '@mui/material';
import { motion } from 'framer-motion';
import { moveUpGently } from 'lib/animation';
import Image from 'next/image';

import SectionHeader from './shared/SectionHeader';

type Image = {
  url?: string | null;
  title?: string | null;
  width?: number | null;
  height?: number | null;
} | null;

interface ImageSectionProps {
  title: string;
  description: string;
  titleAlign?: string;
  descriptionAlign?: string;
  titleColor?: string;
  descriptionColor?: string;
  backgroundColor?: string;
  image?: Image;
  imageTablet?: Image;
  imageMobile?: Image;
}

const ImageSection: React.FC<ImageSectionProps> = ({
  title,
  description,
  titleAlign,
  descriptionAlign,
  titleColor,
  descriptionColor,
  backgroundColor,
  image,
  imageTablet,
  imageMobile,
}) => {
  return (
    <Box component="section" sx={{ bgcolor: backgroundColor }}>
      <Container
        maxWidth="lg"
        sx={{
          py: {
            xs: 10,
            sm: 12.5,
            md: 15,
          },
          px: 3,
        }}
      >
        <SectionHeader
          title={title}
          description={description}
          titleAlign={titleAlign}
          descriptionAlign={descriptionAlign}
          titleColor={titleColor}
          descriptionColor={descriptionColor}
          mb={3}
        />
        {/* desktop image */}
        <Box
          display={{
            xs: 'none',
            sm: 'none',
            md: 'flex',
          }}
          position="relative"
          justifyContent="center"
          component={motion.div}
          sx={{
            aspectRatio: `${image?.width} / ${image?.height}`,
          }}
          {...moveUpGently()}
        >
          {image ? (
            <Image src={image?.url ?? ''} alt={image?.title ?? ''} fill />
          ) : null}
        </Box>

        {/* tablet image */}
        <Box
          display={{
            xs: 'none',
            sm: 'flex',
            md: 'none',
          }}
          position="relative"
          justifyContent="center"
          component={motion.div}
          sx={{
            aspectRatio: `${imageTablet?.width} / ${imageTablet?.height}`,
          }}
          {...moveUpGently()}
        >
          {imageTablet ? (
            <Image
              src={imageTablet?.url ?? ''}
              alt={imageTablet?.title ?? ''}
              fill
            />
          ) : null}
        </Box>

        {/* mobile image */}
        <Box
          display={{
            xs: 'flex',
            sm: 'none',
            md: 'none',
          }}
          position="relative"
          justifyContent="center"
          component={motion.div}
          sx={{
            aspectRatio: `${imageMobile?.width} / ${imageMobile?.height}`,
          }}
          {...moveUpGently()}
        >
          {imageMobile ? (
            <Image
              src={imageMobile?.url ?? ''}
              alt={imageMobile?.title ?? ''}
              fill
            />
          ) : null}
        </Box>
      </Container>
    </Box>
  );
};

export default ImageSection;
