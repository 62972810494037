import React from 'react';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import {
  Box,
  Card,
  Chip,
  Container,
  IconButton,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { motion } from 'framer-motion';
import { moveUpGently } from 'lib/animation';
import { Colors } from 'styles/colors';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import SectionHeader from './shared/SectionHeader';

type ReviewCard = {
  title?: string | null;
  description?: string | null;
  tags?: (string | null)[] | null;
};

interface ReviewCardSectionProps {
  title?: string;
  description?: string;
  titleAlign?: string;
  descriptionAlign?: string;
  titleColor?: string;
  descriptionColor?: string;
  backgroundColor?: string;
  cards: (ReviewCard | null)[];
}

const StyledChip = styled(Chip)(({ theme }: any) => ({
  '&.MuiChip-root': {
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: Colors.grey1,
  },
}));

const ReviewCard: React.FC<ReviewCard> = ({ title, description, tags }) => {
  return (
    <Card
      elevation={0}
      sx={{
        boxShadow:
          '2px 2px 24px rgba(0, 0, 0, 0.02), 0px 2px 12px rgba(0, 0, 0, 0.04)',
        borderRadius: 4,
        padding: 2.5,
        height: '100%',
      }}
    >
      <Box display="flex" flexWrap="wrap" gap={1} mb={1}>
        {tags?.map((tag, index) => (
          <StyledChip
            key={index}
            label={
              <Typography variant="body2" fontWeight={700}>
                {tag}
              </Typography>
            }
            size="small"
          />
        ))}
      </Box>
      <Typography variant="subtitle2" mb={0.5}>
        {title}
      </Typography>
      <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
        {description}
      </Typography>
    </Card>
  );
};

const StyledSwiper = styled(Swiper)`
  // for box shadow
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  margin-top: -20px !important;
  margin-bottom: -20px !important;
`;

const ReviewCardSection: React.FC<ReviewCardSectionProps> = ({
  title,
  description,
  titleAlign = 'start',
  descriptionAlign = 'start',
  titleColor,
  descriptionColor,
  backgroundColor,
  cards,
}) => {
  const theme = useTheme();

  const isMobileOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));
  const isTabletOrSmaller = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallDesktopOrSmaller = useMediaQuery(theme.breakpoints.down('lg'));

  const [slidesPerView, setSlidesPerView] = React.useState(4.5);
  const prevRef = React.useRef(null);
  const nextRef = React.useRef(null);

  const [containerMarginLeft, setContainerMarginLeft] = React.useState(1);
  const containerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const container = containerRef.current;
    if (container) {
      setContainerMarginLeft(container.offsetLeft + 24);
    }

    const changeContainerMarginLeft = () => {
      if (container) {
        setContainerMarginLeft(container.offsetLeft + 24);
      }
    };

    window.addEventListener('resize', changeContainerMarginLeft);

    return () => {
      window.removeEventListener('resize', changeContainerMarginLeft);
    };
  }, []);

  React.useEffect(() => {
    if (isMobileOrSmaller) {
      setSlidesPerView(1.2);
    } else if (isTabletOrSmaller) {
      setSlidesPerView(2.5);
    } else if (isSmallDesktopOrSmaller) {
      setSlidesPerView(3.5);
    } else {
      setSlidesPerView(4.5);
    }
  }, [isMobileOrSmaller, isTabletOrSmaller, isSmallDesktopOrSmaller]);

  return (
    <Box
      component="section"
      display="flex"
      flexDirection="column"
      gap={3}
      sx={{
        py: {
          xs: 10,
          sm: 12.5,
          md: 15,
        },
        bgcolor: backgroundColor,
      }}
    >
      {title ? (
        <Container maxWidth="lg" ref={containerRef}>
          <SectionHeader
            title={title}
            description={description}
            titleAlign={titleAlign}
            descriptionAlign={descriptionAlign}
            titleColor={titleColor}
            descriptionColor={descriptionColor}
          />
        </Container>
      ) : null}

      <Box component={motion.div} {...moveUpGently(0)}>
        <StyledSwiper
          modules={[Navigation, Pagination]}
          mousewheel
          spaceBetween={16}
          slidesPerView={slidesPerView}
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          slidesOffsetBefore={containerMarginLeft}
        >
          {cards.map((card, index) => (
            <SwiperSlide key={index} style={{ height: 'unset' }}>
              <ReviewCard
                title={card?.title}
                description={card?.description}
                tags={card?.tags}
              />
            </SwiperSlide>
          ))}
        </StyledSwiper>
      </Box>

      <Container
        maxWidth="lg"
        sx={{
          display: isTabletOrSmaller ? 'none' : undefined,
        }}
      >
        <Box display="flex" gap={1} justifyContent="flex-end">
          <IconButton
            ref={prevRef}
            sx={{
              border: 1,
              borderColor: Colors.blueGray1,
            }}
          >
            <ArrowBack />
          </IconButton>
          <IconButton
            ref={nextRef}
            sx={{
              border: 1,
              borderColor: Colors.blueGray1,
            }}
          >
            <ArrowForward />
          </IconButton>
        </Box>
      </Container>
    </Box>
  );
};

export default ReviewCardSection;
