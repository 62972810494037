import React from 'react';
import {
  Box,
  Button,
  Portal,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Colors } from 'styles/colors';
import { isEnd, isStart } from 'utils/times';

interface CtaStickyFooterProps {
  title: string;
  description: string;
  buttonTitle: string;
  buttonLink: string;
  startTime?: string | null;
  endTime?: string | null;
}

const CtaStickyFooterContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Portal>
      <Box
        position="fixed"
        bottom={0}
        left={0}
        width="100%"
        px={{
          xs: 0,
          sm: 0,
          md: 3,
        }}
        display="flex"
        justifyContent="center"
        maxWidth="lg"
        zIndex={10}
        sx={{
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        {children}
      </Box>
    </Portal>
  );
};

const CtaStickyFooter: React.FC<CtaStickyFooterProps> = ({
  title,
  description,
  buttonTitle,
  buttonLink,
  startTime,
  endTime,
}) => {
  const theme = useTheme();
  const isTabletOrSmaller = useMediaQuery(theme.breakpoints.down('md'));

  if (isStart(startTime) && !isEnd(endTime)) {
    return (
      <CtaStickyFooterContainer>
        <Box
          component="footer"
          display="flex"
          justifyContent="space-between"
          px={3}
          py={2}
          flexGrow={1}
          bgcolor="white"
          gap={1}
          sx={{
            borderTopLeftRadius: isTabletOrSmaller ? 0 : theme.spacing(2),
            borderTopRightRadius: isTabletOrSmaller ? 0 : theme.spacing(2),
            borderTop: 1,
            borderLeft: isTabletOrSmaller ? 0 : 1,
            borderRight: isTabletOrSmaller ? 0 : 1,
            borderColor: Colors.grey3,
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            gap={0.5}
          >
            <Typography variant="caption">{title}</Typography>
            <Typography
              variant="body1"
              fontWeight={700}
              color={theme.palette.primary.main}
            >
              {description}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" flexShrink={0}>
            <Button
              variant="contained"
              href={buttonLink}
              disableElevation
              size="large"
            >
              {buttonTitle}
            </Button>
          </Box>
        </Box>
      </CtaStickyFooterContainer>
    );
  }

  return null;
};

export default CtaStickyFooter;
