import React from 'react';
import FaqCardSection from 'components/landing/sections/FaqCardSection';
import HeroSection from 'components/landing/sections/HeroSection';
import ImageCardSection from 'components/landing/sections/ImageCardSection';
import ImageSection from 'components/landing/sections/ImageSection';
import NumericalCardSection from 'components/landing/sections/NumericalCardSection';
import QuoteSection from 'components/landing/sections/QuoteSection';
import ReviewCardSection from 'components/landing/sections/ReviewCardSection';
import SurveyCardSection from 'components/landing/sections/SurveyCardSection';
import VideoCardSection from 'components/landing/sections/VideoCardSection';

import type { LandingSection } from 'types/landing';

interface PageSectionsProps {
  sections: LandingSection[];
}

const PageSections: React.FC<PageSectionsProps> = ({ sections }) => {
  return (
    <>
      {sections.map(section => {
        switch (section?.__typename) {
          case 'SectionNumericalCard':
            return (
              <NumericalCardSection
                key={section.sys.id}
                title={section?.title ?? ''}
                description={section?.description ?? ''}
                titleAlign={section?.metadata?.titleAlign ?? undefined}
                descriptionAlign={
                  section?.metadata?.descriptionAlign ?? undefined
                }
                titleColor={section?.metadata?.titleColor ?? undefined}
                descriptionColor={
                  section?.metadata?.descriptionColor ?? undefined
                }
                backgroundColor={
                  section?.metadata?.backgroundColor ?? undefined
                }
                cards={section?.cardsCollection?.items ?? []}
              />
            );

          case 'SectionImageCard':
            return (
              <ImageCardSection
                key={section.sys.id}
                title={section.title ?? ''}
                description={section.description ?? ''}
                titleAlign={section?.metadata?.titleAlign ?? undefined}
                descriptionAlign={
                  section?.metadata?.descriptionAlign ?? undefined
                }
                titleColor={section?.metadata?.titleColor ?? undefined}
                descriptionColor={
                  section?.metadata?.descriptionColor ?? undefined
                }
                backgroundColor={
                  section?.metadata?.backgroundColor ?? undefined
                }
                cards={section?.cardsCollection?.items ?? []}
              />
            );

          case 'SectionSurveyCard':
            return (
              <SurveyCardSection
                key={section.sys.id}
                title={section.title ?? ''}
                description={section.description ?? ''}
                titleAlign={section?.metadata?.titleAlign ?? undefined}
                descriptionAlign={
                  section?.metadata?.descriptionAlign ?? undefined
                }
                titleColor={section?.metadata?.titleColor ?? undefined}
                descriptionColor={
                  section?.metadata?.descriptionColor ?? undefined
                }
                backgroundColor={
                  section?.metadata?.backgroundColor ?? undefined
                }
                cards={section?.cardsCollection?.items ?? []}
              />
            );

          case 'SectionReviewCard':
            return (
              <ReviewCardSection
                key={section.sys.id}
                title={section.title ?? undefined}
                description={section.description ?? undefined}
                titleAlign={section?.metadata?.titleAlign ?? undefined}
                descriptionAlign={
                  section?.metadata?.descriptionAlign ?? undefined
                }
                titleColor={section?.metadata?.titleColor ?? undefined}
                descriptionColor={
                  section?.metadata?.descriptionColor ?? undefined
                }
                backgroundColor={
                  section?.metadata?.backgroundColor ?? undefined
                }
                cards={section?.cardsCollection?.items ?? []}
              />
            );

          case 'SectionFaqCard':
            return (
              <FaqCardSection
                key={section.sys.id}
                title={section.title ?? ''}
                description={section.description ?? ''}
                titleAlign={section?.metadata?.titleAlign ?? undefined}
                descriptionAlign={
                  section?.metadata?.descriptionAlign ?? undefined
                }
                titleColor={section?.metadata?.titleColor ?? undefined}
                descriptionColor={
                  section?.metadata?.descriptionColor ?? undefined
                }
                backgroundColor={
                  section?.metadata?.backgroundColor ?? undefined
                }
                cards={section?.cardsCollection?.items ?? []}
              />
            );

          case 'SectionVideoCard':
            return (
              <VideoCardSection
                key={section.sys.id}
                title={section.title ?? ''}
                description={section.description ?? ''}
                titleAlign={section?.metadata?.titleAlign ?? undefined}
                descriptionAlign={
                  section?.metadata?.descriptionAlign ?? undefined
                }
                titleColor={section?.metadata?.titleColor ?? undefined}
                descriptionColor={
                  section?.metadata?.descriptionColor ?? undefined
                }
                backgroundColor={
                  section?.metadata?.backgroundColor ?? undefined
                }
                cards={section?.cardsCollection?.items ?? []}
              />
            );

          case 'SectionHero':
            return (
              <HeroSection
                key={section.sys.id}
                items={section?.itemsCollection?.items ?? []}
              />
            );

          case 'SectionImage':
            return (
              <ImageSection
                key={section.sys.id}
                title={section.title ?? ''}
                description={section.description ?? ''}
                titleAlign={section?.metadata?.titleAlign ?? undefined}
                descriptionAlign={
                  section?.metadata?.descriptionAlign ?? undefined
                }
                titleColor={section?.metadata?.titleColor ?? undefined}
                descriptionColor={
                  section?.metadata?.descriptionColor ?? undefined
                }
                backgroundColor={
                  section?.metadata?.backgroundColor ?? undefined
                }
                image={section.image}
                imageTablet={section.imageTablet}
                imageMobile={section.imageMobile}
              />
            );

          case 'SectionQuote':
            return (
              <QuoteSection
                key={section.sys.id}
                title={section.title ?? undefined}
                titleAlign={section?.metadata?.titleAlign ?? undefined}
                titleColor={section?.metadata?.titleColor ?? undefined}
                content={section.content ?? ''}
                contentColor={section.contentColor ?? ''}
                backgroundColor={
                  section?.metadata?.backgroundColor ?? undefined
                }
                contentAlign={section.contentAlign ?? undefined}
              />
            );
        }
      })}
    </>
  );
};

export default PageSections;
