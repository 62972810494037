import * as React from 'react';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getCookie } from 'lib/utils/getCookie';
import { setCookie } from 'lib/utils/setCookie';
import Image from 'next/image';
import { isEnd, isStart } from 'utils/times';

interface PopupDialogProps {
  id: string;
  imageUrl: string;
  imageTitle: string;
  startTime?: string | null;
  endTime?: string | null;
}

const PopupDialog: React.FC<PopupDialogProps> = ({
  id,
  imageUrl,
  imageTitle,
  startTime,
  endTime,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(false);

  React.useEffect(() => {
    if (
      isStart(startTime) &&
      !isEnd(endTime) &&
      !getCookie(`popup-dialog-${id}`)
    ) {
      setIsOpen(true);
    }
  }, [id, startTime, endTime]);

  const handleClose = () => {
    if (isChecked) {
      setCookie(`popup-dialog-${id}`, 'hide');
    }
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      {imageUrl ? (
        <Image
          src={imageUrl}
          alt={imageTitle}
          width={414}
          height={330}
          objectFit="cover"
        />
      ) : null}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked}
              onChange={(event, checked) => setIsChecked(checked)}
            />
          }
          label={<Typography variant="body1">오늘 하루 보지 않기</Typography>}
        />
        <Button
          onClick={handleClose}
          autoFocus
          variant="contained"
          color="inherit"
          size="large"
          disableElevation
        >
          닫기
        </Button>
      </Box>
    </Dialog>
  );
};

export default PopupDialog;
